
export const productsData = [
    {
      "id": 1,
      "name": "Smartphone X",
      "description": "A high-end smartphone with a sleek design and powerful features.",
      "price": 999.99,
      "rating": 4.9,
        "mrp_price":1000,
      "imageUrl": "front/img//products/img-5.png"
    },
    {
      "id": 2,
      "name": "Wireless Headphones",
      "description": "Noise-cancelling over-ear headphones with crystal clear sound.",
      "price": 200.00,
      "rating": 4.7,
      "mrp_price":1000,
      "imageUrl": "front/img//products/img-5.png"
    },
    {
      "id": 3,
      "name": "Laptop Pro",
      "description": "A powerful laptop for professionals, with a high-resolution display and fast processor.",
      "price": 1299.99,
      "mrp_price":10900,
      "rating": 3.6,

      "imageUrl": "front/img//products/img-5.png"
    },
    {
      "id": 4,
      "name": "Smartwatch",
      "description": "A sleek and stylish smartwatch with fitness tracking features.",
      "price": 249.99,
      "mrp_price":1000,
      "rating": 5, 
      "imageUrl": "front/img//products/img-5.png"
    },
    {
      "id": 5,
      "name": "Bluetooth Speaker",
      "description": "Portable Bluetooth speaker with deep bass and long battery life.",
      "price": 89.99,
      "mrp_price":1000,
      "rating": 4.6,
      "imageUrl": "front/img//products/img-5.png"
    },

    {
        "id": 6,
        "name": "Smartphone X",
        "description": "A high-end smartphone with a sleek design and powerful features.",
        "price": 999.99,
        "mrp_price":1000,
        "rating": 4.9,
  
        "imageUrl": "front/img//products/img-5.png"
      },
      {
        "id": 7,
        "name": "Wireless Headphones",
        "description": "Noise-cancelling over-ear headphones with crystal clear sound.",
        "price": 199.99,
        "mrp_price":1000,
        "rating": 4.7,
  
        "imageUrl": "front/img//products/img-5.png"
      },
      {
        "id": 8,
        "name": "Laptop Pro",
        "description": "A powerful laptop for professionals, with a high-resolution display and fast processor.",
        "price": 1299.99,
        "mrp_price":1000,
        "rating": 3.6,
  
        "imageUrl": "front/img//products/img-5.png"
      },
      {
        "id": 9,
        "name": "Smartwatch",
        "description": "A sleek and stylish smartwatch with fitness tracking features.",
        "price": 249.99,
        "mrp_price":1000,
        "rating": 5, 
        "imageUrl": "front/img//products/img-5.png"
      },
      {
        "id": 10,
        "name": "Bluetooth Speaker",
        "description": "Portable Bluetooth speaker with deep bass and long battery life.",
        "price": 89.99,
        "mrp_price":1000,
        "rating": 4.6,
        "imageUrl": "front/img//products/img-5.png"
      }
  ];


  export const categoryData =
  [

    {
        id:0,
        name:'New Arrivals',
        slug:'new-arrivals',
        imageUrl:'/front/img/category/new-arrivals.jpg',
        status:1,
        home:1,
    },
    {
        id:1,
        name:'Gadgets',
        slug:'gadgets',
        imageUrl:'/front/img/category/Gadgets.jpg',
        status:1,
        home:1,
    },

    {
        id:2,
        name:'Kids',
        slug:'kids',
        imageUrl:'/front/img/category/kids.jpg',
        status:1,
        home:1,
    },

    {
        id:3,
        name:'Home & Kitchen',
        slug:'home-&-kitchen',
        imageUrl:'/front/img/category/Home-Kitchen.jpg',

        status:1,
        home:1,
    },

    {
        id:4,
        name:'Bueaty & Health',
        slug:'bueaty-&-health',
        imageUrl:'/front/img/category/Beauty-Health.jpg',

        status:1,
        home:1,
    },

    {
        id:5,
        name:'Trending Products',
        slug:'trending-products',
        imageUrl:'/front/img/category/Trending-Product.jpg',

        status:1,
        home:1,
    },

    {
        id:6,
        name:'Car & Bike Accessories',
        slug:'car-&-bike-accessories',
        imageUrl:'/front/img/category/Car-Bike-Accessories.jpg',

        status:1,
        home:1,
    },

    {
        id:7,
        name:'Hot Selling',
        slug:'hot-selling',
        imageUrl:'/front/img/category/hot-sale.jpg',

        status:1,
        home:1,
    },

  ];