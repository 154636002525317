export const settings =[
        {
            id:1,
            name:'API Configuration',
            logo:'front/img/icon/api-configuration.png',
            url:'/api-configuration',
            size_class:'col-lg-4',

        },
        {
            id:2,
            name:'Company Info',
            logo:'front/img/icon/company-info.png',
            url:'/company-info',
            size_class:'col-lg-4',

        },

        {
            id:3,
            name:'Payment',
            logo:'front/img/icon/payment-setting.png',
            url:'/payment-setting',
            size_class:'col-lg-4',

        },

        {
            id:4,
            name:'Droshipper',
            logo:'front/img/icon/dropshipper.png',
            url:'/dropshipper',
            size_class:'col-lg-4', 
        },

        {
            id:5,
            name:'Contract',
            logo:'front/img/icon/contract.png',
            url:'/contract',
            size_class:'col-lg-4',

        },

        {
            id:6,
            name:'Account Settings',
            logo:'front/img/icon/account-setting.png',
            url:'/account-setting',
            size_class:'col-lg-4',
        }
];

export const InnerSettingsTab =[
    {
        id:1,
        name:'API Configuration',
        logo:'front/img/icon/api-configuration.png',
        url:'/api-configuration',
        size_class:'col-lg-2',

    },
    {
        id:2,
        name:'Company Info',
        logo:'front/img/icon/company-info.png',
        url:'/company-info',
        size_class:'col-lg-2',

    },

    {
        id:3,
        name:'Payment',
        logo:'front/img/icon/payment-setting.png',
        url:'/payment-setting',
        size_class:'col-lg-2',

    },

    {
        id:4,
        name:'Droshipper',
        logo:'front/img/icon/dropshipper.png',
        url:'/dropshipper',
        size_class:'col-lg-2', 
    },

    {
        id:5,
        name:'Contract',
        logo:'front/img/icon/contract.png',
        url:'/contract',
        size_class:'col-lg-2',

    },

    {
        id:6,
        name:'Account Settings',
        logo:'front/img/icon/account-setting.png',
        url:'/account-setting',
        size_class:'col-lg-2',
    }
];


export const supportData = 

{

    heading: '10:15AM-6:00PM (Monday-Saturday)',
    phone1: '+91 9308478866',
    phone2: '+91 0612-4063228',
    email: 'info@routeway.in',

    supportCart:[
            {
                id:1,
                heading:'Shipment Related Queries',
                logo:'front/img/icon/shippment-queries.png', 
                phone1: '+91 9472414146',
                phone2: '', 
                email: 'support@routeway.in',
                content: 'Email subject should be start with Shipment (Shipment ......Your query....)',
            },
            {
                id:2,
                heading:'Billing & Remittance',
                logo:'front/img/icon/billing-icon.png', 
                phone1: '0612 4063228,',
                phone2: '+91 9308478866', 
                email: 'account@routeway.in',
                content: 'Email subject should be start with account (Account ......Your query....)',
            },
            {
                id:3,
                heading:'Dashboard Training',
                logo:'front/img/icon/billing-icon.png', 
                phone1: '+91-8544461016',
                phone2: '', 
                email: 'support@routeway.in',
                content: 'Email subject should be start with training (Training ......Your query....)',
            },

            
    ],

    youTubeVideo:[
        {
            id:1,
            heading:'How to Configuration / Connect With Shopify',
            url:'https://www.youtube.com/embed/2hqnP8znZ8U',
            title: 'Configuration / Connect With Shopify'

        },
        {
            id:2,
            heading:'How to Order Confirm, Cancel Order, Process Order',
            url:'https://www.youtube.com/embed/mMOyALisePE',
            title: 'How to Order Confirm, Cancel Order, Process Order'


        }
    ]
}

