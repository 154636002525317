import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Category from '../page/category';
import { baseUrl } from '../helper/helper';


export default function Sidebar() {

    const loaction = useLocation();
    const currentUrl = loaction.pathname;
    const accountUrls = ['/payment-invoice', '/purchase-bill'];
    const settingUrls = ['/setting', '/api-configuration', '/company-info', '/payment-setting' , '/dropshipper', '/contract' , '/account-setting'];
  return (
    <>
       
    <nav className="sidebar">
        <div className="logo d-flex justify-content-between">
            <Link className="large_logo" to="/"><img src={`${baseUrl}front/img/logo.png`} alt="" /></Link>
            <Link className="small_logo" to="/"><img src={`${baseUrl}front/img/mini_logo.png`} alt="" /></Link>
            <div className="sidebar_close_icon d-lg-none">
                <i className="ti-close"></i>
            </div>
        </div>
        <ul id="sidebar_menu">
            <li className="">
                <Link to="/" aria-expanded="false" className={`${currentUrl==="/"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-house"></i>
                    </div>
                    <div className="nav_title">
                        <span>Home</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="/analytics" aria-expanded="false" className={`${currentUrl==="/analytics"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-chart-simple"></i>
                    </div>
                    <div className="nav_title">
                        <span>Analytics</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="/products" aria-expanded="false" className={`${currentUrl==="/products"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-box"></i>
                    </div>
                    <div className="nav_title">
                        <span>Products</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="/push-on-portal" aria-expanded="false" className={`${currentUrl==="/push-on-portal"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-cart-plus"></i>
                    </div>
                    <div className="nav_title">
                        <span>Push On Portal</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="/orders" aria-expanded="false" className={`${currentUrl==="/orders"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-bag-shopping"></i>
                    </div>
                    <div className="nav_title">
                        <span>Orders</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="/orders-status" aria-expanded="false" className={`${currentUrl==="/orders-status"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-chart-column"></i>
                    </div>
                    <div className="nav_title">
                        <span>Order Status</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="/upload-bulk-order" aria-expanded="false" className={`${currentUrl==="/upload-bulk-order"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-box-open"></i>
                    </div>
                    <div className="nav_title">
                        <span>Upload Bulk Order</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="/ndr" aria-expanded="false" className={`${currentUrl==="/ndr"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-truck"></i>
                    </div>
                    <div className="nav_title">
                        <span>NDR</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="#" aria-expanded="false" className={`has-arrow ${accountUrls.includes(currentUrl) ? 'active' : ''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-user"></i>
                    </div>
                    <div className="nav_title">
                        <span>Account </span>
                    </div>
                </Link>
                <ul className={` ${accountUrls.includes(currentUrl) ? 'mm-show' : ''}`}>
                    <li><Link to="/payment-invoice" className={`${currentUrl==="/payment-invoice"?'sub_active':''}`}>Payment Invoice</Link></li>
                    <li><Link to="/purchase-bill" className={`${currentUrl==="/purchase-bill"?'sub_active':''}`}>Sales Invoice</Link></li>
                    <li><Link to="/purchase-bill" className={`${currentUrl==="/payment-invoices"?'sub_active':''}`}>Purchase Bill</Link></li>
                </ul>
            </li>
            <li className="">
                <Link to="/setting" aria-expanded="false" className={` ${settingUrls.includes(currentUrl) ? 'active' : ''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-gear"></i>
                    </div>
                    <div className="nav_title">
                        <span>Settings</span>
                    </div>
                </Link>
            </li>
            <li className="">
                <Link to="/support" aria-expanded="false" className={`${currentUrl==="/support"?'active':''}`}>
                    <div className="nav_icon_small">
                        <i className="fa-solid fa-user-gear"></i>
                    </div>
                    <div className="nav_title">
                        <span>Support</span>
                    </div>
                </Link>
            </li>
        </ul>
    </nav>
    </>
  )
}
