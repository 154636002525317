import React, { useEffect, useState } from 'react'

import Header from '../../components/header'
import Footer from '../../components/footer'
import { InnerSettingsTab, settings } from '../../Http/data/frontData'
import SettingCart from '../../components/settingComponents/settingCart'

const ApiConfiguration = () => {

   
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

   
  useEffect(()=>{
          const $ = window.$; // Access jQuery from the global window object
              if ($ && $.fn.niceSelect) {
              $('.nice_Select').niceSelect();

              return ()=>{
                  $('.nice_Select').niceSelect('destroy');
              }

              // date picker
              


              } else {
              console.error('jQuery or niceSelect is not available');
              }
      },[])



  return (
    <>
      
      <section className="main_content dashboard_part large_header_bg">
      <Header />
      <div className="main_content_iner overly_inner"> 
            <div className="container-fluid p-0 ">
                    <div className="row">

                    <div className="col-md-12">
                            <div className="user_crm_wrapper">
                                <div className="row">

                                {InnerSettingsTab.map((item)=>(
                                        <SettingCart key={item.id} data={item}/> 
                                    ))} 


                                </div>

                            </div>
                       </div>

                       {/* =========================================start filter============================================= */}

                       <div className="col-lg-12">
                            <div className="white_card card_height_100 mb_30 filterCard">
                              <div className="white_card_header carHeadertwo">
                                <div className="box_header m-0">
                                  <div className="main-title">
                                    <h3 className="m-0">Filters</h3>
                                    <button
                                                    className="btn btn-link "
                                                    onClick={handleToggle}
                                                    aria-expanded={!isCollapsed}
                                                    aria-controls="filter"
                                                >
                                      <i className="fa-solid fa-filter" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`white_card_body collapse ${isCollapsed?'':'show'}`}
                                id="filter"
                                aria-labelledby="headingOne"
                                data-parent="#accordion"
                              >
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="form-group">
                                      <label htmlFor="ProductName">Configuration</label>
                                      <select className="nice_Select wide" id="ProductName">
                                        <option value="">--select--</option>
                                        <option value={1}>Shopify</option>
                                        <option value={2}>RouteWay</option>
                                        <option value={3}>WordPress</option>
                                        <option value={4}>Wix</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label htmlFor="PaymentMethod">Store Name</label>
                                      <input className="form-control" type="text" />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label htmlFor="PaymentMethod">Web Portal</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="ex(https://ShopifyConfiguration.com)"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label htmlFor="PaymentMethod">Access Code</label>
                                      <input className="form-control" type="text" />
                                    </div>
                                  </div>
                                  <div className="col-md-1">
                                    <label
                                      htmlFor="PaymentMethod"
                                      style={{ display: "block", visibility: "hidden" }}
                                    >
                                      Date
                                    </label>
                                    <button type="submit" className="btn btn-primary">
                                      <i className="fa-solid fa-magnifying-glass" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> 
                         {/* ==============================end filter==================================== */}


                         {/* ==============================start table section==================================== */}

                         <div className="col-lg-12">
                              <div className="white_card card_height_100 mb_20 ">
                                <div className="white_card_body QA_section orderList">
                                  <div className="QA_table  mt-3 paymentInvoiceList">
                                    <div className="QA_table tab-content mt-3">
                                      <div id="" className="dataTables_wrapper no-footer ">
                                        <table className="table orderlisttable dataTable p-0 collapsed ">
                                          <thead>
                                            <tr role="row">
                                              <th>S.no</th>
                                              <th>Provider</th>
                                              <th>Cart Name</th>
                                              <th>URL</th>
                                              <th>pixel</th>
                                              <th>Key</th>
                                              <th colSpan={3}>Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr role="row" className="odd">
                                              <td>1</td>
                                              <td>Shopify</td>
                                              <td>
                                                <input
                                                  className="form-control"
                                                  type="text"
                                                  defaultValue="Patel Solutions"
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  className="form-control"
                                                  type="text"
                                                  defaultValue="https://ad473f.myshopify.com"
                                                />
                                              </td>
                                              <td>
                                                <input className="form-control" type="text" />
                                              </td>
                                              <td>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</td>
                                              <td>
                                                <a href="#" className="status_btn" title="Deactivate Now">
                                                  Active
                                                </a>
                                              </td>
                                              <td>
                                                <a href="#" className="action_btn mr_10">
                                                  
                                                  <i className="fa-solid fa-cloud-arrow-down" />
                                                </a>
                                              </td>
                                              <td>
                                                
                                                <a href="#" className="btn btn-primary smallbtn">
                                                  
                                                  Save
                                                </a>
                                              </td>
                                            </tr>
                                            <tr role="row" className="odd">
                                              <td>2</td>
                                              <td>RouteWay</td>
                                              <td>
                                                <input
                                                  className="form-control"
                                                  type="text"
                                                  defaultValue="myMart"
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  className="form-control"
                                                  type="text"
                                                  defaultValue="https://01mymart.ind.in"
                                                />
                                              </td>
                                              <td>
                                                <input className="form-control" type="text" />
                                              </td>
                                              <td>e332c3c50e395337c121d06abfe1fd6a</td>
                                              <td>
                                                <a href="#" className="status_btn" title="Deactivate Now">
                                                  Active
                                                </a>
                                              </td>
                                              <td>
                                                <a href="#" className="action_btn mr_10">
                                                  
                                                  <i className="fa-solid fa-cloud-arrow-down" />
                                                </a>
                                              </td>
                                              <td>
                                                
                                                <a href="#" className="btn btn-primary smallbtn">
                                                  
                                                  Save
                                                </a>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>


                         {/* ==============================end table section==================================== */}
                    </div> 
            </div>
        </div>

        <Footer />
        </section>
    </>
  )
}

export default ApiConfiguration
