import React, { useEffect, useState } from 'react'

const InvoiceFilter = () => {

    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
      setIsCollapsed(!isCollapsed);
    };

     
    useEffect(()=>{
            const $ = window.$; // Access jQuery from the global window object
                if ($ && $.fn.niceSelect) {
                $('.nice_Select').niceSelect();

                return ()=>{
                    $('.nice_Select').niceSelect('destroy');
                }

                // date picker
                


                } else {
                console.error('jQuery or niceSelect is not available');
                }
        },[])
    
    
  return (
    <>
      
      <div className="col-lg-12">
  <div className="white_card card_height_100 mb_30 filterCard">
    <div className="white_card_header carHeadertwo">
      <div className="box_header m-0">
        <div className="main-title">
          <h3 className="m-0">Filters</h3>
          <button
                className="btn btn-link "
                onClick={handleToggle}
                aria-expanded={!isCollapsed}
                 aria-controls="filter"
              >
            <i className="fa-solid fa-filter" />
          </button>
        </div>
      </div>
    </div>
    <div
      className={`white_card_body collapse ${isCollapsed ? '' : 'show'}`}
      id="filter"
      aria-labelledby="headingOne"
      data-parent="#accordion"
    >
      <div className="row">
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="PaymentMethod">Date</label>
            <input
              className="form-control datepicker-here  digits"
              type="text"
              data-range="true"
              data-multiple-dates-separator=" - "
              data-language="en"
            />
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="storeSelect">Invoice No.</label>
            <input type="text" className="form-control" />
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="OrderStatus">Select Status</label>
            <select id="OrderStatus" className="nice_Select wide">
              <option value="">Select status</option>
              <option value={1}>Dues </option>
              <option value={2}>Paid </option>
              <option value={3}>Adjusted_with_freight_deduction </option>
              <option value={4}>Paid_with_freight_deduction </option>
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <label
            htmlFor="PaymentMethod"
            style={{ display: "block", visibility: "hidden" }}
          >
            Date
          </label>
          <button type="button" className="btn btn-secondary">
            Reset
          </button>
          <button type="submit" className="btn btn-primary">
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default InvoiceFilter
