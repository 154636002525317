import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { InnerSettingsTab, settings } from '../../Http/data/frontData'
import SettingCart from '../../components/settingComponents/settingCart'


const CompanyInfo = () => {
  return (
    <div>
      
      <section className="main_content dashboard_part large_header_bg">
      <Header />
      <div className="main_content_iner overly_inner"> 
            <div className="container-fluid p-0 ">
                    <div className="row">

                    <div className="col-md-12">
                            <div className="user_crm_wrapper">
                                <div className="row">

                                {InnerSettingsTab.map((item)=>(
                                        <SettingCart key={item.id} data={item}/> 
                                    ))}
                                 
                                </div>
                            </div>
                            </div>

                    </div> 
              {/* =========================================start Basic Company Info============================================= */}

              <div className="col-lg-12">
                        <div className="white_card card_height_100 mb_20 ">
                          <div className="white_card_header">
                            <div className="box_header m-0">
                              <div className="main-title">
                                <h3 className="m-0">Basic Company Info</h3>
                              </div>
                              <div className="header_more_tool">
                                <div className="dropdown">
                                  <span
                                    className="dropdown-toggle"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                  >
                                    <i className="ti-more-alt" />
                                  </span>
                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a className="dropdown-item" href="#">
                                      
                                      <i className="ti-trash" /> Delete
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      
                                      <i className="ti-printer" /> Print
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      
                                      <i className="fa fa-download" /> Download
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="white_card_body QA_section">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label htmlFor="PaymentMethod">Company Name</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    defaultValue="BPR NETWORK INDIA PVT LTD"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label htmlFor="PaymentMethod">Webiste URL</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    defaultValue="https://shopping.patelsolutions.in/"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label htmlFor="PaymentMethod">Email</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    defaultValue="info@patelsolutions.in"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label htmlFor="PaymentMethod">Mobile(1)</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    defaultValue={9852006290}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label htmlFor="PaymentMethod">Mobile(2)</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    defaultValue={9852006290}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label htmlFor="PaymentMethod">GST No</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    defaultValue="10AAICB8928P1Z8"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label htmlFor="PaymentMethod">District</label>
                                  <input className="form-control" type="text" defaultValue="patna" />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label htmlFor="PaymentMethod">Pincode</label>
                                  <input className="form-control" type="text" defaultValue={800012} />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label htmlFor="ProductName">State</label>
                                  <select className="nice_Select wide" id="ProductName">
                                    <option value="">--select--</option>
                                    <option value="AP">Andhra Pradesh</option>
                                    <option value="AR">Arunachal Pradesh</option>
                                    <option value="AS">Assam</option>
                                    <option value="BR" selected="">
                                      Bihar
                                    </option>
                                    <option value="CT">Chhattisgarh</option>
                                    <option value="GA">Gujarat</option>
                                    <option value="HR">Haryana</option>
                                    <option value="HP">Himachal Pradesh</option>
                                    <option value="JK">Jammu and Kashmir</option>
                                    <option value="GA">Goa</option>
                                    <option value="JH">Jharkhand</option>
                                    <option value="KA">Karnataka</option>
                                    <option value="KL">Kerala</option>
                                    <option value="MP">Madhya Pradesh</option>
                                    <option value="MH">Maharashtra</option>
                                    <option value="MN">Manipur</option>
                                    <option value="ML">Meghalaya</option>
                                    <option value="MZ">Mizoram</option>
                                    <option value="NL">Nagaland</option>
                                    <option value="OR">Odisha</option>
                                    <option value="PB">Punjab</option>
                                    <option value="RJ">Rajasthan</option>
                                    <option value="SK">Sikkim</option>
                                    <option value="TN">Tamil Nadu</option>
                                    <option value="TG">Telangana</option>
                                    <option value="TR">Tripura</option>
                                    <option value="UT">Uttarakhand</option>
                                    <option value="UP">Uttar Pradesh</option>
                                    <option value="WB">West Bengal</option>
                                    <option value="AN">Andaman and Nicobar Islands</option>
                                    <option value="CH">Chandigarh</option>
                                    <option value="DN">Dadra and Nagar Haveli</option>
                                    <option value="DD">Daman and Diu</option>
                                    <option value="DL">Delhi</option>
                                    <option value="LD">Lakshadweep</option>
                                    <option value="PY">Puducherry</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-9">
                                <div className="form-group">
                                  <label htmlFor="PaymentMethod">Address</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    defaultValue="Chitrakut Nagar, Patel Niwas-2B/47, Digha, Danapur"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

              {/* =========================================start Basic Company Info============================================= */}
              {/* =========================================start Authorized Person Info============================================= */}
              
              <div className="col-lg-12">
                    <div className="white_card card_height_100 mb_20 ">
                      <div className="white_card_header">
                        <div className="box_header m-0">
                          <div className="main-title">
                            <h3 className="m-0">Authorized Person Info</h3>
                          </div>
                          <div className="header_more_tool">
                            <div className="dropdown">
                              <span
                                className="dropdown-toggle"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                              >
                                <i className="ti-more-alt" />
                              </span>
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a className="dropdown-item" href="#">
                                  
                                  <i className="ti-trash" /> Delete
                                </a>
                                <a className="dropdown-item" href="#">
                                  
                                  <i className="ti-printer" /> Print
                                </a>
                                <a className="dropdown-item" href="#">
                                  
                                  <i className="fa fa-download" /> Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="white_card_body QA_section">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="PaymentMethod">Contact Name</label>
                              <input
                                className="form-control"
                                type="text"
                                defaultValue="Bibhu Kumar"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="PaymentMethod">Contact mob 1</label>
                              <input
                                className="form-control"
                                type="text"
                                defaultValue={9852006290}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="PaymentMethod">Contact mob 2</label>
                              <input
                                className="form-control"
                                type="text"
                                defaultValue={9852006290}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="PaymentMethod">PAN Number</label>
                              <input
                                className="form-control"
                                type="text"
                                defaultValue="AUSPK2220Q"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="PaymentMethod">Aadhar no</label>
                              <input
                                className="form-control"
                                type="text"
                                defaultValue={0}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

              
              {/* =========================================end Authorized Person Info============================================= */}
              <div className="col-md-3">
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Update</button>
                    </div>
                </div>
            </div>

           


        </div>

        <Footer />
        </section>

    </div>
  )
}

export default CompanyInfo
