import React, { useEffect } from 'react'
import Header from '../components/header'
import { useRef } from 'react';
import 'datatables.net-dt/css/jquery.dataTables.css'; // Include the DataTables styles
import $ from 'jquery';
import 'datatables.net';
import { loadScript } from '../helper/helper';
// import 'datatables.net-responsive';

const BulkUpload = () => {

  const tableRef = useRef(null);
    
     
    useEffect(()=>{

        loadScript('../front/vendors/datatable/js/dataTables.responsive.min.js');
        const $ = window.$; // Access jQuery from the global window object
            if ($ && $.fn.niceSelect) {
            $('.nice_Select').niceSelect();

            return ()=>{
                $('.nice_Select').niceSelect('destroy');
            }

            // date picker
            


            } else {
            console.error('jQuery or niceSelect is not available');
            }
    },[])


    
  useEffect(() => {
    // Check if the table exists and initialize DataTable
    const $table = $(tableRef.current);
    
    if ($table.length) {
      $table.DataTable({
        bLengthChange: false,
        "bDestroy": false,
        language: {
            search: "<i class='ti-search'></i>",
            searchPlaceholder: 'Quick Search',
            paginate: {
                next: "<i class='ti-arrow-right'></i>",
                previous: "<i class='ti-arrow-left'></i>"
            }
        },
        columnDefs: [{
            visible: false
        }],
        responsive: true,
        searching: false,
        info: false,
        paging: false
      });
    }

    // Cleanup on component unmount
    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $table.DataTable().destroy();
      }
    };
  }, []);


  return (
    <>
       <section className="main_content dashboard_part large_header_bg">
       <Header />

       <div className="main_content_iner overly_inner">
            <div className="p-0 container-fluid "> 
                <div className="row">
                <div className="col-lg-12">
                        <div className="white_card mb_20 ">
                            <div className="white_card_header">
                                <div className="row">
                                    <div className="col-md-12 mb_20">
                                        <div className="form-group ">
                                            <label for="storeSelect">Upload orders from</label>
                                            <select className="nice_Select wide" id="storeSelect">
                                                <option className="text-sm font-semibold">Select-One</option>
                                                <option className="text-sm font-semibold" value="3">Shopify-Patel solutions (https://ad473f.myshopify.com)</option>
                                                <option className="text-sm font-semibold" value="66">RouteWay-myMart (https://01mymart.ind.in)</option>
                                                <option className="text-sm font-semibold" value="10652">Shopify-routewayapptest (routewayapptest.myshopify.com)</option>
                                                <option className="text-sm font-semibold" value="20698">Shopify-ssdfsdf (dfsdfs)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group" style={{margin: '0px'}}>
                                            <input type="file" className="form-control" style={{height: 'unset'}} />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group" style={{margin: '0px'}}>
                                            <select className="nice_Select wide" id="storeSelect">
                                                {/* <!-- <option className="text-sm font-semibold">Select-One</option>
                                                <option className="text-sm font-semibold" value="3">Shopify-Patel solutions (https://ad473f.myshopify.com)</option>
                                                <option className="text-sm font-semibold" value="66">RouteWay-myMart (https://01mymart.ind.in)</option>
                                                <option className="text-sm font-semibold" value="10652">Shopify-routewayapptest (routewayapptest.myshopify.com)</option>
                                                <option className="text-sm font-semibold" value="20698">Shopify-ssdfsdf (dfsdfs)</option> --> */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2"><button type="button" className="pushonBtn btn btn-primary rounded-pill">Upload <i className="fa-solid fa-cloud-arrow-up"></i></button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ====================================================================================== */}

                <div className="row">
                <div className="col-lg-12">
                        <div className="white_card card_height_100 mb_20 ">
                            <div className="white_card_body QA_section pushportalPadding">
                                <div className="QA_table ">
                                    <div id="" className="dataTables_wrapper no-footer">
                                        <div id="DataTables_Table_1_wrapper" className="dataTables_wrapper no-footer">
                                            <table className="table p-0 tableDatatotal lms_table_active2 dataTable no-footer dtr-inline" id="DataTables_Table_1" role="grid" style={{width: '1162px'}}
                                            ref={tableRef}>
                                            <thead>
                                                <tr role="row">
                                                    <th scope="col" className="sorting_asc" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1" colspan="1"
                                                        style={{width: '401.2px'}} aria-label="S.no: activate to sort column descending" aria-sort="ascending">
                                                        S.no</th>
                                                    <th scope="col" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1"
                                                        aria-label="Order_id: activate to sort column ascending" colspan="1" style={{width: '41px'}}>Order_id</th>
                                                    <th scope="col" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1"
                                                        aria-label="Order_no: activate to sort column ascending" colspan="1" style={{width: '82px'}}>Order_no</th>
                                                    <th scope="col" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1"
                                                        aria-label="Order_date: activate to sort column ascending" colspan="1" style={{width: '99px'}}>Order_date
                                                    </th>
                                                    <th scope="col" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1"
                                                        aria-label="Shi_Add_Name: activate to sort column ascending" colspan="1" style={{width: '129px'}}>Shi_Add_Name</th>
                                                    <th scope="col" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1"
                                                        aria-label="Shi_Add_Add1: activate to sort column ascending" colspan="1" style={{width: '72px'}}>Shi_Add_Add1</th>
                                                    <th scope="col" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1"
                                                        aria-label="Shi_Add_Add2: activate to sort column ascending" colspan="1" style={{width: '37px'}}>Shi_Add_Add2</th>
                                                    <th scope="col" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1"
                                                        aria-label="Shi_Add_City: activate to sort column ascending" colspan="1" style={{width: '59.2px'}}>Shi_Add_City</th>
                                                    <th scope="col" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1"
                                                        aria-label="Shi_Add_State: activate to sort column ascending" colspan="1" style={{width: '59.2px'}}>Shi_Add_State</th>
                                                    <th scope="col" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1"
                                                        aria-label="Shi_Add_Zip: activate to sort column ascending" colspan="1" style={{width: '59.2px'}}>Shi_Add_Zip</th>
                                                    <th scope="col" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1"
                                                        aria-label="Shi_Add_phone: activate to sort column ascending" colspan="1" style={{width: '59.2px'}}>Shi_Add_phone</th>
                                                    <th scope="col" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1"
                                                        aria-label="SKU: activate to sort column ascending" colspan="1" style={{width: '59.2px'}}>SKU</th>
                                                    <th scope="col" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1"
                                                        aria-label="Product Name: activate to sort column ascending" colspan="1" style={{width: '59.2px'}}>Product Name</th>
                                                    <th scope="col" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1"
                                                        aria-label="Quantity: activate to sort column ascending" colspan="1" style={{width: '59.2px'}}>Quantity</th>
                                                    <th scope="col" className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1"
                                                        aria-label="price: activate to sort column ascending" colspan="1" style={{width: '59.2px'}}>price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                
                                            </tbody>
                                        </table></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

              </div>
             </div>
        </section>
    </>
  )
}

export default BulkUpload
