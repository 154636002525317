import React from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'
import InvoiceFilter from './components/invoiceFilter'

const PurchaseBill = () => {
  return (
    <>
        <section className="main_content dashboard_part large_header_bg">
       <Header />

       <div className="main_content_iner overly_inner ">
            <div className="container-fluid p-0 ">
                    <div className="row">
                            <InvoiceFilter /> 
                {/* =================================================================== */}

                <div className="col-lg-12">
                        <div className="white_card card_height_100 mb_20">
                            <div className="white_card_body QA_section orderList">
                                <div className="QA_table tab-content mt-3 paymentInvoiceList" id="myTabContent">
                                    <div className="tab-pane fade active show" id="COD" role="tabpanel" aria-labelledby="COD-tab">
                                        <div className="QA_table tab-content mt-3">
                                            <div id="" className="dataTables_wrapper no-footer ">
                                                <table className="table orderlisttable dataTable p-0 collapsed ">
                                                    <thead>
                                                        <tr role="row">
                                                            <th>S.no</th>
                                                            <th>Bill No</th>
                                                            <th>Up to</th>
                                                            <th>On Date</th>
                                                            <th>Total (GST include) </th>
                                                            <th>To be Deduct</th>
                                                            <th>View</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr role="row" className="odd">
                                                            <td>1</td>
                                                            <td>38</td>
                                                            <td>30/Nov/2023</td>
                                                            <td>30/Nov/2023</td>
                                                            <td>5803.24</td>
                                                            <td>885.24</td>
                                                            <td><a href="javascript:void(0)"><i className="fa-solid fa-print"></i></a></td>
                                                        </tr>
                                                        <tr role="row" className="odd">
                                                            <td>2</td>
                                                            <td>38</td>
                                                            <td>30/Nov/2023</td>
                                                            <td>30/Nov/2023</td>
                                                            <td>5803.24</td>
                                                            <td>885.24</td>
                                                            <td><a href="javascript:void(0)"><i className="fa-solid fa-print"></i></a></td>
                                                        </tr>
                                                        <tr role="row" className="odd">
                                                            <td>3</td>
                                                            <td>38</td>
                                                            <td>30/Nov/2023</td>
                                                            <td>30/Nov/2023</td>
                                                            <td>5803.24</td>
                                                            <td>885.24</td>
                                                            <td><a href="javascript:void(0)"><i className="fa-solid fa-print"></i></a></td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="statements" role="tabpanel" aria-labelledby="statements-tab">
                                        <div className="QA_table tab-content mt-3">
                                            <div id="" className="dataTables_wrapper no-footer ">
                                                <table className="table orderlisttable dataTable p-0 collapsed ">
                                                    <thead>
                                                        <tr role="row">
                                                            <th>S.no</th>
                                                            <th>On date</th>
                                                            <th>Debit</th>
                                                            <th>Credit</th>
                                                            <th>Narration</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr role="row" className="odd">
                                                            <td>1</td>
                                                            <td>09 Sep 2023</td>
                                                            <td>977</td>
                                                            <td>0</td>
                                                            <td>Payment Transfer UTR, 3360611053</td>
                                                        </tr>
                                                        <tr role="row" className="odd">
                                                            <td>1</td>
                                                            <td>09 Sep 2023</td>
                                                            <td>977</td>
                                                            <td>0</td>
                                                            <td>Payment Transfer UTR, 3360611053</td>
                                                        </tr>
                                                        <tr role="row" className="odd">
                                                            <td>1</td>
                                                            <td>09 Sep 2023</td>
                                                            <td>977</td>
                                                            <td>0</td>
                                                            <td>Payment Transfer UTR, 3360611053</td>
                                                        </tr>

                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                              
                            </div>
                        </div>
                    </div>

                {/* ======================================================= */}

 
                    </div>

            </div>

       </div> 
        <Footer />
       </section>
    </>
  )
}

export default PurchaseBill
