 
import Footer from '../../components/footer'
import Header from '../../components/header'
import InvoiceFilter from './components/invoiceFilter'
import Filter from '../../components/analyticComponents/fillter'
import { baseUrl, loadScript } from '../../helper/helper'

import CountUp from 'react-countup';

const PaymentInvoice = () => {

       
  return (
    <> 
      <section className="main_content dashboard_part large_header_bg">
       <Header />

       <div class="main_content_iner overly_inner ">
            <div class="container-fluid p-0 ">
                    <div class="row">
                            <InvoiceFilter />

                            <div className="col-md-12">
                <div className="user_crm_wrapper">
                    <div className="row">
                    <div className="col-lg-2">
                        <div className="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                        <div className="plan_left d-flex align-items-center">
                            <div className="thumb">
                            <img src={`${baseUrl}front/img/icon/cod.png`} alt="" />
                            </div>
                            <div>
                            <h5>
                                <span className="counter">
                                <CountUp
                                    start={0}
                                    end={100}
                                    duration={5000 / 1000} // Convert duration from milliseconds to seconds
                                    delay={0.1} // Delay before the counter starts, if needed
                                />
                                </span>
                            </h5>
                            <span>COD</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="dashdataCard d-flex align-items-center justify-content-between">
                        <div className="plan_left d-flex align-items-center">
                            <div className="thumb">
                            <img src={`${baseUrl}front/img/icon/rto.png`} alt="" />
                            </div>
                            <div>
                            <h5>
                                <span className="counter">
                                <CountUp
                                    start={0}
                                    end={45}
                                    duration={5000 / 1000} // Convert duration from milliseconds to seconds
                                    delay={0.1} // Delay before the counter starts, if needed
                                />
                                </span>
                            </h5>
                            <span>RTO</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                        <div className="plan_left d-flex align-items-center">
                            <div className="thumb">
                            <img src={`${baseUrl}front/img/icon/save.png`} alt="" />
                            </div>
                            <div>
                            <h5>
                                <span className="counter">
                                <CountUp
                                    start={0}
                                    end={30}
                                    duration={5000 / 1000} // Convert duration from milliseconds to seconds
                                    delay={0.1} // Delay before the counter starts, if needed
                                />
                                </span>
                            </h5>
                            <span>Ser+GST</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                        <div className="plan_left d-flex align-items-center">
                            <div className="thumb">
                            <img src={`${baseUrl}front/img/icon/database-storage.png`} alt="" />
                            </div>
                            <div>
                            <h5>
                                <span className="counter">
                                <CountUp
                                    start={0}
                                    end={20}
                                    duration={5000 / 1000} // Convert duration from milliseconds to seconds
                                    delay={0.1} // Delay before the counter starts, if needed
                                />
                                </span>
                            </h5>
                            <span>Total Payable</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                        <div className="plan_left d-flex align-items-center">
                            <div className="thumb">
                            <img src={`${baseUrl}front/img/icon/wallet.png`} alt="" />
                            </div>
                            <div>
                            <h5>
                                <span className="counter">
                                <CountUp
                                    start={0}
                                    end={10}
                                    duration={5000 / 1000} // Convert duration from milliseconds to seconds
                                    delay={0.1} // Delay before the counter starts, if needed
                                />
                                </span>
                            </h5>
                            <span>Paid</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                        <div className="plan_left d-flex align-items-center">
                            <div className="thumb">
                            <img src={`${baseUrl}front/img/icon/clock.png`} alt="" />
                            </div>
                            <div>
                            <h5>
                                <span className="counter">
                                <CountUp
                                    start={0}
                                    end={10}
                                    duration={5000 / 1000} // Convert duration from milliseconds to seconds
                                    delay={0.1} // Delay before the counter starts, if needed
                                />
                                </span>
                            </h5>
                            <span>Next Payment</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* =================================================================== */}

                <div className="col-md-12">
                    <div className="normalText">
                        <p>
                        <strong>
                            Payment will release every Saturday in a week except national holiday
                        </strong>
                        ( If the hold amount is more than or equal to 5000/-, then amount will be
                        disbursed on any day )
                        </p>
                        <p>
                        Hold amount will reconsile / credited on day 15 or 30 of every month
                        accoridng to your "In-Transit" order(s)
                        </p>
                    </div>
                    </div>

                    {/* ======================================================= */}


                    <div className="col-lg-12">
                        <div className="white_card card_height_100 mb_20 mt-3">
                            <div className="white_card_body QA_section orderList">
                            <div className="bulder_tab_wrapper">
                                <div className="paymentInvoiceLink">
                                <ul className="nav" id="myTab" role="tablist">
                                    <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        id="COD-tab"
                                        data-toggle="tab"
                                        href="#COD"
                                        role="tab"
                                        aria-controls="COD"
                                        aria-selected="true"
                                    >
                                        <i className="fa-solid fa-list" /> COD
                                    </a>
                                    </li>
                                    <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="statements-tab"
                                        data-toggle="tab"
                                        href="#statements"
                                        role="tab"
                                        aria-controls="statements"
                                        aria-selected="false"
                                    >
                                        <i className="fa-solid fa-arrow-right-arrow-left" /> Statements
                                    </a>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            <div
                                className="QA_table tab-content mt-3 paymentInvoiceList"
                                id="myTabContent"
                            >
                                <div
                                className="tab-pane fade active show"
                                id="COD"
                                role="tabpanel"
                                aria-labelledby="COD-tab"
                                >
                                <div className="QA_table tab-content mt-3">
                                    <div id="" className="dataTables_wrapper no-footer ">
                                    <table className="table orderlisttable dataTable p-0 collapsed ">
                                        <thead>
                                        <tr role="row">
                                            <th>S.no</th>
                                            <th>Ref</th>
                                            <th>On Date</th>
                                            <th>Deli_Qty</th>
                                            <th>Deli_Value</th>
                                            <th>RTQ_Qty</th>
                                            <th>RTO_value</th>
                                            <th>Total Value</th>
                                            <th>Ser_Cost</th>
                                            <th>Gst</th>
                                            <th>Total_Payable</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr role="row" className="odd">
                                            <td>1</td>
                                            <td>
                                            <a
                                                href="javascript:void(0)"
                                                data-toggle="modal"
                                                data-target="#OrderDetails"
                                            >
                                                <i className="fa-solid fa-circle-exclamation" />
                                            </a>
                                            </td>
                                            <td>30 May 2024</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>1</td>
                                            <td>70</td>
                                            <td>-70</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>-70</td>
                                        </tr>
                                        <tr role="row" className="odd">
                                            <td>1</td>
                                            <td>
                                            <a
                                                href="javascript:void(0)"
                                                data-toggle="modal"
                                                data-target="#OrderDetails"
                                            >
                                                <i className="fa-solid fa-circle-exclamation" />
                                            </a>
                                            </td>
                                            <td>30 May 2024</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>1</td>
                                            <td>70</td>
                                            <td>-70</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>-70</td>
                                        </tr>
                                        <tr role="row" className="odd">
                                            <td>1</td>
                                            <td>
                                            <a
                                                href="javascript:void(0)"
                                                data-toggle="modal"
                                                data-target="#OrderDetails"
                                            >
                                                <i className="fa-solid fa-circle-exclamation" />
                                            </a>
                                            </td>
                                            <td>30 May 2024</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>1</td>
                                            <td>70</td>
                                            <td>-70</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>-70</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                </div>
                                <div
                                className="tab-pane fade"
                                id="statements"
                                role="tabpanel"
                                aria-labelledby="statements-tab"
                                >
                                <div className="QA_table tab-content mt-3">
                                    <div id="" className="dataTables_wrapper no-footer ">
                                    <table className="table orderlisttable dataTable p-0 collapsed ">
                                        <thead>
                                        <tr role="row">
                                            <th>S.no</th>
                                            <th>On date</th>
                                            <th>Debit</th>
                                            <th>Credit</th>
                                            <th>Narration</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr role="row" className="odd">
                                            <td>1</td>
                                            <td>09 Sep 2023</td>
                                            <td>977</td>
                                            <td>0</td>
                                            <td>Payment Transfer UTR, 3360611053</td>
                                        </tr>
                                        <tr role="row" className="odd">
                                            <td>1</td>
                                            <td>09 Sep 2023</td>
                                            <td>977</td>
                                            <td>0</td>
                                            <td>Payment Transfer UTR, 3360611053</td>
                                        </tr>
                                        <tr role="row" className="odd">
                                            <td>1</td>
                                            <td>09 Sep 2023</td>
                                            <td>977</td>
                                            <td>0</td>
                                            <td>Payment Transfer UTR, 3360611053</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>


                    {/* ================================================================================ */}
  
</div>


                    </div>

            </div>

       </div>
{/* 
       <div calssName="main_content_iner overly_inner ">
            <div calssName="container-fluid p-0 ">
                <div calssName="row">
                    <div calssName="col-lg-12">
                        <div calssName="white_card card_height_100 mb_30 filterCard">
                            <div calssName="white_card_header carHeadertwo">
                                <div calssName="box_header m-0">
                                    <div calssName="main-title">
                                        <h3 calssName="m-0">Filters</h3>
                                        <button calssName="collapsed" data-toggle="collapse" data-target="#filter"
                                            aria-expanded="false" aria-controls="filter"><i
                                                calssName="fa-solid fa-filter"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div calssName="white_card_body collapse" id="filter" aria-labelledby="headingOne"
                                data-parent="#accordion">
                                <div calssName="row">
                                    <div calssName="col-md-3 mb-3">
                                        <div calssName="form-group">
                                            <label for="PaymentMethod">Date</label>
                                            <input calssName="form-control datepicker-here  digits" type="text"
                                                data-range="true" data-multiple-dates-separator=" - "
                                                data-language="en" />
                                        </div>
                                    </div>
                                    <div calssName="col-md-3 mb-3">
                                        <div calssName="form-group">
                                            <label for="storeSelect">Invoice No.</label>
                                            <input type="text" calssName="form-control" />
                                        </div>
                                    </div>
                                    <div calssName="col-md-3 mb-3">
                                        <div calssName="form-group">
                                            <label for="OrderStatus">Select Status</label>
                                            <select id="OrderStatus" calssName="nice_Select wide">
                                                <option value="">Select status</option>
                                                <option value="1">Dues </option>
                                                <option value="2">Paid </option>
                                                <option value="3">Adjusted_with_freight_deduction </option>
                                                <option value="4">Paid_with_freight_deduction </option>
                                            </select>

                                        </div>
                                    </div>
                                   
                                    <div calssName="col-md-3">
                                        <label for="PaymentMethod"
                                            style={{display: 'block', visibility: 'hidden'}}>Date</label>
                                        <button type="button" calssName="btn btn-secondary">Reset</button>
                                        <button type="submit" calssName="btn btn-primary">Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div calssName="col-md-12">
                        <div calssName="user_crm_wrapper">
                            <div calssName="row">
                                <div calssName="col-lg-2">
                                    <div calssName="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                                        <div calssName="plan_left d-flex align-items-center">
                                            <div calssName="thumb">
                                                <img src="img/icon/cod.png" alt=""/>
                                            </div>
                                            <div>
                                                <h5><span calssName="counter">1000</span></h5>
                                                <span>COD</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div calssName="col-lg-2">
                                    <div calssName="dashdataCard d-flex align-items-center justify-content-between">
                                        <div calssName="plan_left d-flex align-items-center">
                                            <div calssName="thumb">
                                                <img src="img/icon/rto.png" alt="" />
                                            </div>
                                            <div>
                                                <h5><span calssName="counter">45</span></h5>
                                                <span>RTO</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div calssName="col-lg-2">
                                    <div calssName="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                                        <div calssName="plan_left d-flex align-items-center">
                                            <div calssName="thumb">
                                                <img src="img/icon/save.png" alt="" />
                                            </div>
                                            <div>
                                                <h5><span calssName="counter">30</span></h5>
                                                <span>Ser+GST</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div calssName="col-lg-2">
                                    <div calssName="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                                        <div calssName="plan_left d-flex align-items-center">
                                            <div calssName="thumb">
                                                <img src="img/icon/database-storage.png" alt="" />
                                            </div>
                                            <div>
                                                <h5><span calssName="counter">20</span></h5>
                                                <span>Total Payable</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div calssName="col-lg-2">
                                    <div calssName="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                                        <div calssName="plan_left d-flex align-items-center">
                                            <div calssName="thumb">
                                                <img src="img/icon/wallet.png" alt="" />
                                            </div>
                                            <div>
                                                <h5><span calssName="counter">10</span></h5>
                                                <span>Paid</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                                <div calssName="col-lg-2">
                                    <div calssName="dashdataCard mb-3 d-flex align-items-center justify-content-between">
                                        <div calssName="plan_left d-flex align-items-center">
                                            <div calssName="thumb">
                                                <img src="img/icon/clock.png" alt="" />
                                            </div>
                                            <div>
                                                <h5><span calssName="counter">8</span></h5>
                                                <span>Next Payment</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               

                            </div>
                        </div>

                    </div>

                    <div calssName="col-md-12">
                        <div calssName="normalText">
                            <p><strong>Payment will release every Saturday in a week except national holiday </strong> ( If the hold amount is more than or equal to 5000/-, then amount will be disbursed on any day )</p>
                            <p>Hold amount will reconsile / credited on day 15 or 30 of every month accoridng to your "In-Transit" order(s)</p>
                        </div>
                    </div>

                    <div calssName="col-lg-12">
                        <div calssName="white_card card_height_100 mb_20 mt-3">
                            <div calssName="white_card_body QA_section orderList">
                                <div calssName="bulder_tab_wrapper">
                                    <div calssName="paymentInvoiceLink">
                                        <ul calssName="nav" id="myTab" role="tablist">
                                            <li calssName="nav-item">
                                                <a calssName="nav-link active" id="COD-tab" data-toggle="tab" href="#COD" role="tab" aria-controls="COD" aria-selected="true"><i calssName="fa-solid fa-list"></i> COD</a>
                                            </li>
                                            <li calssName="nav-item">
                                                <a calssName="nav-link" id="statements-tab" data-toggle="tab" href="#statements" role="tab" aria-controls="statements"
                                                    aria-selected="false"><i calssName="fa-solid fa-arrow-right-arrow-left"></i> Statements</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div calssName="QA_table tab-content mt-3 paymentInvoiceList" id="myTabContent">
                                    <div calssName="tab-pane fade active show" id="COD" role="tabpanel" aria-labelledby="COD-tab">
                                        <div calssName="QA_table tab-content mt-3">
                                            <div id="" calssName="dataTables_wrapper no-footer ">
                                                <table calssName="table orderlisttable dataTable p-0 collapsed ">
                                                    <thead>
                                                        <tr role="row">
                                                            <th>S.no</th>
                                                            <th>Ref</th>
                                                            <th>On Date</th>
                                                            <th>Deli_Qty</th>
                                                            <th>Deli_Value</th>
                                                            <th>RTQ_Qty</th>
                                                            <th>RTO_value</th>
                                                            <th>Total Value</th>
                                                            <th>Ser_Cost</th>
                                                            <th>Gst</th>
                                                            <th>Total_Payable</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr role="row" calssName="odd">
                                                            <td>1</td>
                                                            <td><a href="javascript:void(0)" data-toggle="modal" data-target="#OrderDetails"><i calssName="fa-solid fa-circle-exclamation"></i></a></td>
                                                            <td>30 May 2024</td>
                                                            <td>0</td>
                                                            <td>0</td>
                                                            <td>1</td>
                                                            <td>70</td>
                                                            <td>-70</td>
                                                            <td>0</td>
                                                            <td>0</td>
                                                            <td>-70</td>
                                                        </tr>
                                                        <tr role="row" calssName="odd">
                                                            <td>1</td>
                                                            <td><a href="javascript:void(0)" data-toggle="modal" data-target="#OrderDetails"><i calssName="fa-solid fa-circle-exclamation"></i></a></td>
                                                            <td>30 May 2024</td>
                                                            <td>0</td>
                                                            <td>0</td>
                                                            <td>1</td>
                                                            <td>70</td>
                                                            <td>-70</td>
                                                            <td>0</td>
                                                            <td>0</td>
                                                            <td>-70</td>
                                                        </tr>
                                                        <tr role="row" calssName="odd">
                                                            <td>1</td>
                                                            <td><a href="javascript:void(0)" data-toggle="modal" data-target="#OrderDetails"><i calssName="fa-solid fa-circle-exclamation"></i></a></td>
                                                            <td>30 May 2024</td>
                                                            <td>0</td>
                                                            <td>0</td>
                                                            <td>1</td>
                                                            <td>70</td>
                                                            <td>-70</td>
                                                            <td>0</td>
                                                            <td>0</td>
                                                            <td>-70</td>
                                                        </tr>

                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                  
                                    <div calssName="tab-pane fade" id="statements" role="tabpanel" aria-labelledby="statements-tab">
                                        <div calssName="QA_table tab-content mt-3">
                                            <div id="" calssName="dataTables_wrapper no-footer ">
                                                <table calssName="table orderlisttable dataTable p-0 collapsed ">
                                                    <thead>
                                                        <tr role="row">
                                                            <th>S.no</th>
                                                            <th>On date</th>
                                                            <th>Debit</th>
                                                            <th>Credit</th>
                                                            <th>Narration</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr role="row" calssName="odd">
                                                            <td>1</td>
                                                            <td>09 Sep 2023</td>
                                                            <td>977</td>
                                                            <td>0</td>
                                                            <td>Payment Transfer UTR, 3360611053</td>
                                                        </tr>
                                                        <tr role="row" calssName="odd">
                                                            <td>1</td>
                                                            <td>09 Sep 2023</td>
                                                            <td>977</td>
                                                            <td>0</td>
                                                            <td>Payment Transfer UTR, 3360611053</td>
                                                        </tr>
                                                        <tr role="row" calssName="odd">
                                                            <td>1</td>
                                                            <td>09 Sep 2023</td>
                                                            <td>977</td>
                                                            <td>0</td>
                                                            <td>Payment Transfer UTR, 3360611053</td>
                                                        </tr>

                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div> */}
        <Footer />
       </section>

    </>
  )
}

export default PaymentInvoice
