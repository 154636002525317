import React, { useEffect } from 'react'

import Header from '../../components/header'
import Footer from '../../components/footer'
import { InnerSettingsTab, settings } from '../../Http/data/frontData'
import SettingCart from '../../components/settingComponents/settingCart'



const Droshipper = () => {

  useEffect(()=>{
    const $ = window.$; // Access jQuery from the global window object
        if ($ && $.fn.niceSelect) {
        $('.nice_Select').niceSelect();

        return ()=>{
            $('.nice_Select').niceSelect('destroy');
        } 

        } else {
        console.error('jQuery or niceSelect is not available');
        }
},[])


  return (
    <>

     <section className="main_content dashboard_part large_header_bg">
      <Header />
      <div className="main_content_iner overly_inner"> 
            <div className="container-fluid p-0 ">
                    <div className="row">

                    <div className="col-md-12">
                            <div className="user_crm_wrapper">
                                <div className="row">

                                {InnerSettingsTab.map((item)=>(
                                        <SettingCart key={item.id} data={item}/> 
                                    ))}
                                 
                                </div>
                            </div>
                            </div>

                    </div> 

                 {/* =========================================Start GST Details============================================= */}
                 
                 <div className="col-lg-12">
                        <div className="white_card card_height_100 mb_20 ">
                          <div className="white_card_header">
                            <div className="box_header m-0">
                              <div className="main-title">
                                <h3 className="m-0">GST Details</h3>
                              </div>
                              <div className="header_more_tool">
                                <div className="dropdown">
                                  <span
                                    className="dropdown-toggle"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                  >
                                    <i className="ti-more-alt" />
                                  </span>
                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a className="dropdown-item" href="#">
                                      
                                      <i className="ti-trash" /> Delete
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      
                                      <i className="ti-printer" /> Print
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      
                                      <i className="fa fa-download" /> Download
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="white_card_body QA_section">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="PaymentMethod">
                                    Name (AS per printed on GST Certificate)
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    defaultValue="Bibhu Sir"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="ProductName">GST Provosional ID</label>
                                  <select className="nice_Select wide" id="ProductName">
                                    <option value="">--select--</option>
                                    <option value={1}>Saving Account </option>
                                    <option value={2} selected="">
                                      Current Account
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="PaymentMethod">PAN Card Information</label>
                                  <input className="form-control" type="text" defaultValue={91852} />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="PaymentMethod">Upload GST Certificate Photo</label>
                                  <input className="form-control" type="file" />
                                  <small>Note : Please upload a GST Certificate Photo.</small>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="PaymentMethod">Upload PAN Card Photo</label>
                                  <input className="form-control" type="file" />
                                  <small>Note : Please upload a PAN Card Photo.</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                 {/* =========================================Start GST Details============================================= */}
                 {/* =========================================Start KYC Details============================================= */}
                 
                 <div className="col-lg-12">
                    <div className="white_card card_height_100 mb_20 ">
                      <div className="white_card_header">
                        <div className="box_header m-0">
                          <div className="main-title">
                            <h3 className="m-0">KYC Details</h3>
                          </div>
                          <div className="header_more_tool">
                            <div className="dropdown">
                              <span
                                className="dropdown-toggle"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                              >
                                <i className="ti-more-alt" />
                              </span>
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a className="dropdown-item" href="#">
                                  
                                  <i className="ti-trash" /> Delete
                                </a>
                                <a className="dropdown-item" href="#">
                                  
                                  <i className="ti-printer" /> Print
                                </a>
                                <a className="dropdown-item" href="#">
                                  
                                  <i className="fa fa-download" /> Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="white_card_body QA_section">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="ProductName">Business Type</label>
                              <select className="nice_Select wide" id="ProductName">
                                <option value="">--select--</option>
                                <option value={1}>Individual </option>
                                <option value={2}>Sole Proprietor</option>
                                <option value={3}>Company</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="PaymentMethod">Upload Photo Identification</label>
                              <input className="form-control" type="file" />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="PaymentMethod">Upload Addhar Card</label>
                              <input className="form-control" type="file" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                 {/* =========================================End KYC Details============================================= */}

            </div>
            <div className="col-md-3">
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Update</button>
                    </div>
                </div>
        </div>

        <Footer />
        </section>

    </>
  )
}

export default Droshipper
