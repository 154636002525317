import React, { useState } from 'react'
import NewOrder from './newOrder';
import FailedToSync from './failedToSync';
import ConfirmOrder from './confirmOrder';
import CancelledOrder from './cancelledOrder';

const OrderSection = () => {

    const [activeTab, setActiveTab] = useState('NewOrder');

    const changeTab=(tab, event)=>{
        event.preventDefault();
        setActiveTab(tab); 
    }

  return (
    <>
      
      <div className="col-md-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="white_card card_height_100 mb_20 ">
                                    <div className="white_card_header">
                                        <div className="m-0 box_header">
                                            <div className="main-title">
                                              <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <select className="nice_Select wide" id="storeSelect">
                                                            <option value="1">RouteWay</option>
                                                            <option value="2">Shopify</option>
                                                        </select>
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="header_more_tool">
                                                <div className="dropdown">
                                                    <span className="dropdown-toggle" id="dropdownMenuButton"
                                                        data-toggle="dropdown">
                                                        <i className="ti-more-alt"></i>
                                                    </span>
                                                    <div className="dropdown-menu dropdown-menu-right"
                                                        aria-labelledby="dropdownMenuButton">
                                                        <a className="dropdown-item" href="#"> <i className="ti-trash"></i>
                                                            Delete</a>
                                                        <a className="dropdown-item" href="#"> <i className="ti-printer"></i>
                                                            Print</a>
                                                        <a className="dropdown-item" href="#"> <i
                                                                className="fa fa-download"></i> Download</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div className="white_card_body QA_section orderList">
                                        <div className="bulder_tab_wrapper">
                                            <ul className="nav" id="myTab" role="tablist">
                                                <li className="nav-item">
                                                    <a className={`nav-link ${activeTab === 'NewOrder' ? 'active' : ''}`} id="newOrderTab" data-toggle="tab" href="#NewOrder" 
                                                    onClick={(e)=>changeTab('NewOrder',e)}><i className="fa-solid fa-cart-plus"></i> New order <span className="orderQty">4</span></a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link ${activeTab === 'faildToSync' ? 'active' : ''}`} id="faildToSyncTab" data-toggle="tab" href="#faildToSync" onClick={(e)=>changeTab('faildToSync',e)}><i className="fa-solid fa-circle-exclamation"></i> Failed to Sync <span className="orderQty">4</span></a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link ${activeTab === 'ordConfirm' ? 'active' : ''}`} id="odrConfirm" data-toggle="tab" href="#ordConfirm" onClick={(e)=>changeTab('ordConfirm',e)}><i className="fa-solid fa-circle-exclamation"></i> Confirm <span className="orderQty">142</span></a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link ${activeTab === 'odrCancel' ? 'active' : ''}`} id="odrCancel" data-toggle="tab" href="#odrCancel"   onClick={(e)=>changeTab('odrCancel',e)}><i className="fa-solid fa-circle-exclamation"></i> Cancel <span className="orderQty">57</span></a>
                                                </li>
                                             
                                            </ul>
                                        </div>
                                        <div>
                                            <div>
                                                <div className="mt-3 QA_table tab-content" id="myTabContent">
                                                    <div id="" className="dataTables_wrapper no-footer ">
                                                        <table className="table p-0 orderlisttable dataTable lms_table_active collapsed ">
                                                            <thead>
                                                                <tr role="row">
                                                                    <th>S.no</th>
                                                                    <th><input type="checkbox" className="formcheck"
                                                                            id="exampleCheck1" /></th>
                                                                    <th scope="col" className="sorting_asc" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        colspan="1" style={{width: '200px'}} aria-sort="ascending"
                                                                        aria-label="Product Name: activate to sort column descending">
                                                                        Item</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="Total: activate to sort column ascending">
                                                                        Order no</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="Selling Price: activate to sort column ascending">
                                                                        Date</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="Cancel By DS: activate to sort column ascending">
                                                                        Qty</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="Cancel By System: activate to sort column ascending">
                                                                        Price</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="Delivered: activate to sort column ascending">
                                                                        Margin </th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="RTO: activate to sort column ascending">
                                                                        Customer</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="Percent: activate to sort column ascending">
                                                                        Mobile</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="Percent: activate to sort column ascending">
                                                                        Status</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="Percent: activate to sort column ascending">
                                                                        Action</th>
                                                                </tr>
                                                            </thead>
                                                           <NewOrder activeTab ={activeTab}/>

                                                           <FailedToSync activeTab={activeTab} />

                                                           <ConfirmOrder activeTab={activeTab} />

                                                            <CancelledOrder activeTab={activeTab} />
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div >
                                               
                                            </div>
                                            <div >
                                             
                                            </div>
                                            <div >
                                             
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

    </>
  )
}

export default OrderSection
