import React from 'react'
import Header from '../../components/header'
import OrderFilter from '../../components/orderFilter'
import OrderSection from '../../components/orderComponebts/OrderSection'
 

const Orders = () => {
  return (
    <>
      <section className="main_content dashboard_part large_header_bg">
       <Header />

       <div className="main_content_iner overly_inner">
            <div className="p-0 container-fluid ">

                <div className="row">
                    <OrderFilter />
                    <OrderSection />
                </div>

              </div>
             </div>
        </section>
    </>
  )
}

export default Orders
