import React, { useEffect } from 'react'

import Header from '../../components/header'
import Footer from '../../components/footer'
import { InnerSettingsTab, settings } from '../../Http/data/frontData'
import SettingCart from '../../components/settingComponents/settingCart'

const PaymentSetting = () => {


  useEffect(()=>{
    const $ = window.$; // Access jQuery from the global window object
        if ($ && $.fn.niceSelect) {
        $('.nice_Select').niceSelect();

        return ()=>{
            $('.nice_Select').niceSelect('destroy');
        }

        // date picker
        


        } else {
        console.error('jQuery or niceSelect is not available');
        }
},[])

  return (
    <div>
      <section className="main_content dashboard_part large_header_bg">
      <Header />
      <div className="main_content_iner overly_inner"> 
            <div className="container-fluid p-0 ">
                    <div className="row">

                    <div className="col-md-12">
                            <div className="user_crm_wrapper">
                                <div className="row">

                                {InnerSettingsTab.map((item)=>(
                                        <SettingCart key={item.id} data={item}/> 
                                    ))}
                                 
                                </div>
                            </div>
                            </div>

                    </div> 
                 {/* =========================================Start Bank Account Details============================================= */}
                 <div className="col-lg-12">
                      <div className="white_card card_height_100 mb_20 ">
                        <div className="white_card_header">
                          <div className="box_header m-0">
                            <div className="main-title">
                              <h3 className="m-0">Bank Account Details</h3>
                            </div>
                            <div className="header_more_tool">
                              <div className="dropdown">
                                <span
                                  className="dropdown-toggle"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                >
                                  <i className="ti-more-alt" />
                                </span>
                                <div
                                  className="dropdown-menu dropdown-menu-right"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    <i className="ti-trash" /> Delete
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    <i className="ti-printer" /> Print
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    {" "}
                                    <i className="fa fa-download" /> Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="white_card_body QA_section">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="PaymentMethod">Beneficiary Name*</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  defaultValue="Bibhu Sir"
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="ProductName">Account Type*</label>
                                <select className="nice_Select wide" id="ProductName">
                                  <option value="">--select--</option>
                                  <option value={1}>Saving Account </option>
                                  <option value={2} selected="">
                                    Current Account
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="PaymentMethod">Account No.*</label>
                                <input className="form-control" type="text" defaultValue={91852} />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="PaymentMethod">Account No.</label>
                                <input className="form-control" type="text" defaultValue={91852} />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="PaymentMethod">IFSc Code</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  defaultValue="254SSDFH"
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="PaymentMethod">Upload Image</label>
                                <input className="form-control" type="file" />
                                <small>Note : Please upload a cancelled cheque.</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                 {/* =========================================End Bank Account Details============================================= */}

            </div>

            <div className="col-md-3">
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Update</button>
                    </div>
                </div>

        </div>

        <Footer />
        </section>

    </div>
  )
}

export default PaymentSetting
