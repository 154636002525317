import React from 'react'

const FailedToSync = ({activeTab}) => {
  return (
    <>
       <tbody className={`tab-pane fade ${activeTab === 'faildToSync' ? 'active show' : ''}`} id="faildToSync" role="tabpanel" aria-labelledby="faildToSync-tab">
        
        <tr role="row" className="odd">
            <td>1</td>
            <td><input type="checkbox" className="formcheck"
                    id="exampleCheck1" /></td>
            <td tabindex="0" className="sorting_1">
                RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2
            </td>
            <td className="color_text_6">#1034 14 Apr 2024</td>
            <td className="color_text_6">21/Apr/2024</td>
            <td className="color_text_6">1</td>
            <td className="color_text_6"> 699</td>
            <td className="color_text_6">475</td>
            <td className="color_text_6">Rajeshbhai -</td>
            <td className="color_text_6">9998163653</td>
            <td><a href="#" className="status_btn">Active</a></td>
            <td className="color_text_6">
                <div className="action_btns d-flex">
                    <a href="#" className="action_btn mr_10"> <i
                            className="far fa-edit"></i> </a>
                    <a href="#" className="action_btn"> <i
                            className="fas fa-trash"></i> </a>
                </div>
            </td>
        </tr>
        <tr role="row" className="odd">
            <td>2</td>
            <td><input type="checkbox" className="formcheck"
                    id="exampleCheck1" /></td>
            <td tabindex="0" className="sorting_1">
                RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2
            </td>
            <td className="color_text_6">#1034 14 Apr 2024</td>
            <td className="color_text_6">21/Apr/2024</td>
            <td className="color_text_6">1</td>
            <td className="color_text_6"> 699</td>
            <td className="color_text_6">475</td>
            <td className="color_text_6">Rajeshbhai -</td>
            <td className="color_text_6">9998163653</td>
            <td><a href="#" className="status_btn">Active</a></td>
            <td className="color_text_6">
                <div className="action_btns d-flex">
                    <a href="#" className="action_btn mr_10"> <i
                            className="far fa-edit"></i> </a>
                    <a href="#" className="action_btn"> <i
                            className="fas fa-trash"></i> </a>
                </div>
            </td>
             
        </tr>

    </tbody>
    </>
  )
}

export default FailedToSync
