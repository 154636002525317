import React, { useEffect, useState } from 'react'
import Footer from '../components/footer'
import Header from '../components/header';

const Ndr = () => {

  
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

      useEffect(()=>{
        const $ = window.$; // Access jQuery from the global window object
            if ($ && $.fn.niceSelect) {
            $('.nice_Select').niceSelect();

            return ()=>{
                $('.nice_Select').niceSelect('destroy');
            }

            // date picker
            


            } else {
            console.error('jQuery or niceSelect is not available');
            }
    },[])



  return (
    <>
      
      <section className="main_content dashboard_part large_header_bg">
       <Header />

       <div className="main_content_iner overly_inner">
            <div className="container-fluid p-0 ">

                <div className="row">
                      
                <div className="col-lg-12">
                        <div className="white_card card_height_100 mb_30 filterCard">
                            <div className="white_card_header carHeadertwo">
                                <div className="box_header m-0">
                                    <div className="main-title">
                                        <h3 className="m-0">Filters</h3>
                                         
                                        <button
                className="btn btn-link "
                onClick={handleToggle}
                aria-expanded={!isCollapsed}
                 aria-controls="filter"
              >
                <i className="fa-solid fa-filter"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className={`white_card_body collapse ${isCollapsed ? '' : 'show'}`} id="filter" aria-labelledby="headingOne"
                                data-parent="#accordion">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label for="PaymentMethod">Date</label>
                                            <input className="form-control datepicker-here  digits" type="text"
                                                data-range="true" data-multiple-dates-separator=" - "
                                                data-language="en" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label for="PaymentMethod">Order ID</label>
                                            <input className="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label for="PaymentMethod">Customer Name</label>
                                            <input className="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label for="ProductName">Product Name</label>
                                            <select className="nice_Select wide" id="ProductName">
                                                <option>-- Select --</option>
                                                <option>RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2</option>
                                                <option>RW322 !!! Mini Electric Shaver</option>
                                                <option>RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label for="OrderStatus">Order Status</label>
                                            <select id="OrderStatus" className="nice_Select wide">
                                                <option value="">Select </option>
                                                <option value="">All </option>
                                                <option value="1" className="">New</option>
                                                <option value="2" className="">Confirmed</option>
                                                <option value="3" className="d-none">Cancelled</option>
                                                <option value="4" className="d-none">Pickup Initiated</option>
                                                <option value="5" className="d-none">Pickup Cancelled</option>
                                                <option value="6" className="d-none">Manifested</option>
                                                <option value="7" className="d-none">Pickup Pending</option>
                                                <option value="8" className="d-none">Pickup Completed</option>
                                                <option value="9" className="d-none">In Transit</option>
                                                <option value="10" className="d-none">Undelivered</option>
                                                <option value="11" className="d-none">Out For Delivery</option>
                                                <option value="12" className="d-none">Delivered</option>
                                                <option value="13" className="d-none">RTO</option>
                                                <option value="14" className="d-none">RTO In Transit</option>
                                                <option value="15" className="d-none">RTO Delivered</option>
                                                <option value="16" className="d-none">Booking in Process</option>
                                                <option value="17" className="d-none">Cancel in Process</option>
                                                <option value="18" className="d-none">Shipment Lost</option>
                                                <option value="19" className="d-none">Shipment Damaged</option>
                                                <option value="20" className="d-none">Order Confirmation In Process</option>
                                                <option value="21" className="d-none">Shipment Cancelled</option>
                                                <option value="22" className="d-none">Out for Pickup</option>
                                                <option value="200" className="d-none">Cancelled on Dropdash</option>
                                                <option value="201" className="d-none">Booked</option>
                                            </select>

                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label for="storeSelect">Store</label>
                                            <select className="nice_Select wide" id="storeSelect">
                                                <option>-- Select --</option>
                                                <option value="1">RouteWay</option>
                                                <option value="2">Shopify</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label for="PaymentMethod"
                                            style={{display: 'block', visibility: 'hidden'}}>Date</label>
                                        <button type="button" className="btn btn-secondary">Reset</button>
                                        <button type="submit" className="btn btn-primary">Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ========================================================================================= */}
                   
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="white_card card_height_100 mb_20 ">
                                    <div className="white_card_header">
                                        <div className="box_header m-0">
                                            <div className="main-title">
                                              <div className="row">
                                                <div className="col-md-12">
                                                    <div className="ndrSelectedOption">
                                                        <h4>Total : <span>0</span></h4>
                                                        <h4>Selected : <span>0</span></h4>
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="header_more_tool">
                                                <div className="dropdown">
                                                    <span className="dropdown-toggle" id="dropdownMenuButton"
                                                        data-toggle="dropdown">
                                                        <i className="ti-more-alt"></i>
                                                    </span>
                                                    <div className="dropdown-menu dropdown-menu-right"
                                                        aria-labelledby="dropdownMenuButton">
                                                        <a className="dropdown-item" href="#"> <i className="ti-trash"></i>
                                                            Delete</a>
                                                        <a className="dropdown-item" href="#"> <i className="ti-printer"></i>
                                                            Print</a>
                                                        <a className="dropdown-item" href="#"> <i
                                                                className="fa fa-download"></i> Download</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- <div className="white_box_tittle list_header mt-3" style="margin-bottom: 0px;">
                                            <div className="box_right d-flex lms_block">
                                                <div className="serach_field_2">
                                                    <div className="search_inner">
                                                        <form active="#">
                                                            <div className="search_field">
                                                                <input type="text" placeholder="Search content here...">
                                                            </div>
                                                            <button type="submit"> <i className="ti-search"></i> </button>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="add_button ml-10">
                                                    <a href="#" data-toggle="modal" data-target="#addcategory" className="btn_1">Add New</a>
                                                </div>
                                            </div>
                                        </div> --> */}
                                    </div>
                                    <div className="white_card_body QA_section orderList">
                                        <div className="bulder_tab_wrapper">
                                            <ul className="nav" id="myTab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="ActionRequiredTab" data-toggle="tab" href="#ActionRequired" role="tab" aria-controls="ActionRequired" aria-selected="true"> Action Required </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="ActionTakenTab" data-toggle="tab" href="#ActionTaken" role="tab" aria-controls="ActionTaken" aria-selected="true"> Action Taken </a>
                                                </li>
                                                
                                                <li className="nav-item">
                                                    <a className="nav-link" id="delivered" data-toggle="tab" href="#delivered" role="tab" aria-controls="delivered" aria-selected="true"> Delivered </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="RTO" data-toggle="tab" href="#RTO" role="tab" aria-controls="RTO" aria-selected="true"> RTO </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className=" " >
                                            <div className="" >
                                                <div className="QA_table tab-content mt-3" id="myTabContent">
                                                    <div id="" className="dataTables_wrapper no-footer ">
                                                        <table className="table orderlisttable dataTable lms_table_active p-0 collapsed">
                                                            <thead>
                                                                <tr role="row">
                                                                    <th>S.no</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="Total: activate to sort column ascending">
                                                                        Order no
                                                                    </th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                    aria-controls="NewOrder" rowspan="1"
                                                                    aria-label="Selling Price: activate to sort column ascending">
                                                                    Date</th>
                                                                    <th scope="col" className="sorting" tabindex="0">SKU</th>
                                                                    <th><input type="checkbox" className="formcheck" id="exampleCheck1" /></th>
                                                                    <th scope="col" className="sorting_asc" tabindex="0" aria-controls="NewOrder" rowspan="1" colspan="1" style={{width: '200px'}} aria-sort="ascending" aria-label="Product Name: activate to sort column descending"> Product</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="Cancel By DS: activate to sort column ascending">
                                                                        Qty</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="Cancel By System: activate to sort column ascending">
                                                                        M/SP</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="RTO: activate to sort column ascending">
                                                                        Customer</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="Percent: activate to sort column ascending">
                                                                        Mobile</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="Percent: activate to sort column ascending">
                                                                        AWB</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="Percent: activate to sort column ascending">
                                                                        Status</th>
                                                                    <th scope="col" className="sorting" tabindex="0"
                                                                        aria-controls="NewOrder" rowspan="1"
                                                                        aria-label="Percent: activate to sort column ascending">
                                                                        Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="tab-pane fade active show"  id="ActionRequired" role="tabpanel" aria-labelledby="ActionRequired-tab">
        
                                                                {/* <!-- <tr role="row" className="odd">
                                                                    <td>1</td>
                                                                    <td><input type="checkbox" className="formcheck"
                                                                            id="exampleCheck1"></td>
                                                                    <td tabindex="0" className="sorting_1">
                                                                        RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2
                                                                    </td>
                                                                    <td className="color_text_6">#1034 14 Apr 2024</td>
                                                                    <td className="color_text_6">21/Apr/2024</td>
                                                                    <td className="color_text_6">1</td>
                                                                    <td className="color_text_6"> 699</td>
                                                                    <td className="color_text_6">475</td>
                                                                    <td className="color_text_6">Rajeshbhai -</td>
                                                                    <td className="color_text_6">9998163653</td>
                                                                    <td><a href="#" className="status_btn">Active</a></td>
                                                                    <td className="color_text_6">
                                                                        <div className="action_btns d-flex">
                                                                            <a href="#" className="action_btn mr_10"> <i
                                                                                    className="far fa-edit"></i> </a>
                                                                            <a href="#" className="action_btn"> <i
                                                                                    className="fas fa-trash"></i> </a>
                                                                        </div>
                                                                    </td>
                                                                </tr> --> */}
                                                        
        
                                                            </tbody>

                                                            <tbody className="tab-pane fade" id="ActionTaken" role="tabpanel" aria-labelledby="ActionTaken-tab">
        
                                                                
                                                            </tbody>

                                                            <tbody className="tab-pane fade" id="delivered" role="tabpanel" aria-labelledby="delivered-tab">
        
                                                                <tr role="row" className="odd">
                                                                    <td>1</td>
                                                                    <td><input type="checkbox" className="formcheck"
                                                                            id="exampleCheck1" /></td>
                                                                    <td tabindex="0" className="sorting_1">
                                                                        RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2
                                                                    </td>
                                                                    <td className="color_text_6">#1034 14 Apr 2024</td>
                                                                    <td className="color_text_6">21/Apr/2024</td>
                                                                    <td className="color_text_6">1</td>
                                                                    <td className="color_text_6"> 699</td>
                                                                    <td className="color_text_6">475</td>
                                                                    <td className="color_text_6">Rajeshbhai -</td>
                                                                    <td className="color_text_6">9998163653</td>
                                                                    <td><a href="#" className="status_btn">Active</a></td>
                                                                    <td className="color_text_6">
                                                                        <div className="action_btns d-flex">
                                                                            <a href="#" className="action_btn mr_10"> <i
                                                                                    className="far fa-edit"></i> </a>
                                                                            <a href="#" className="action_btn"> <i
                                                                                    className="fas fa-trash"></i> </a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr role="row" className="odd">
                                                                    <td>2</td>
                                                                    <td><input type="checkbox" className="formcheck"
                                                                            id="exampleCheck1" /></td>
                                                                    <td tabindex="0" className="sorting_1">
                                                                        RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2
                                                                    </td>
                                                                    <td className="color_text_6">#1034 14 Apr 2024</td>
                                                                    <td className="color_text_6">21/Apr/2024</td>
                                                                    <td className="color_text_6">1</td>
                                                                    <td className="color_text_6"> 699</td>
                                                                    <td className="color_text_6">475</td>
                                                                    <td className="color_text_6">Rajeshbhai -</td>
                                                                    <td className="color_text_6">9998163653</td>
                                                                    <td><a href="#" className="status_btn">Active</a></td>
                                                                    <td className="color_text_6">
                                                                        <div className="action_btns d-flex">
                                                                            <a href="#" className="action_btn mr_10"> <i
                                                                                    className="far fa-edit"></i> </a>
                                                                            <a href="#" className="action_btn"> <i
                                                                                    className="fas fa-trash"></i> </a>
                                                                        </div>
                                                                    </td> 
                                                                </tr>
        
                                                            </tbody>


                                                            <tbody className="tab-pane fade" id="RTO" role="tabpanel" aria-labelledby="RTO-tab">
        
                                                                {/* <!-- <tr role="row" className="odd">
                                                                    <td>1</td>
                                                                    <td><input type="checkbox" className="formcheck"
                                                                            id="exampleCheck1"></td>
                                                                    <td tabindex="0" className="sorting_1">
                                                                        RW453 !!! 3 in 1 BLACK Hair Color Shampoo pack of 2
                                                                    </td>
                                                                    <td className="color_text_6">#1034 14 Apr 2024</td>
                                                                    <td className="color_text_6">21/Apr/2024</td>
                                                                    <td className="color_text_6">1</td>
                                                                    <td className="color_text_6"> 699</td>
                                                                    <td className="color_text_6">475</td>
                                                                    <td className="color_text_6">Rajeshbhai -</td>
                                                                    <td className="color_text_6">9998163653</td>
                                                                    <td><a href="#" className="status_btn">Active</a></td>
                                                                    <td className="color_text_6">
                                                                        <div className="action_btns d-flex">
                                                                            <a href="#" className="action_btn mr_10"> <i
                                                                                    className="far fa-edit"></i> </a>
                                                                            <a href="#" className="action_btn"> <i
                                                                                    className="fas fa-trash"></i> </a>
                                                                        </div>
                                                                    </td>
                                                                </tr> --> */}
        
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div >
                                               
                                            </div>
                                            <div >
                                             
                                            </div>
                                            <div >
                                             
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                   
                </div>
            </div>
        </div>
        <Footer />
       </section>

    </>
  )
}

export default Ndr
