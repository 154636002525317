import Sidebar from "./components/sidebar";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Category from "./page/category";
import HomePage from "./page/home";
import { useEffect } from "react";
import $ from 'jquery';

import './App.css';
import 'metismenu/dist/metisMenu.css';
import 'metismenu';
import ProductDetails from "./page/productDetails"; 
// import Header from "./components/header";

import './front/css/bootstrap.min.css';
import './front/vendors/themefy_icon/themify-icons.css';
import './front/vendors/niceselect/css/nice-select.css';
import './front/vendors/owl_carousel/css/owl.carousel.css';
import './front/vendors/gijgo/gijgo.min.css';
import './front/vendors/font_awesome/css/all.min.css';
import './front/vendors/fontawesome6/css/all.css';
import './front/vendors/tagsinput/tagsinput.css';
import './front/vendors/datepicker/date-picker.css';
import './front/vendors/vectormap-home/vectormap-2.0.2.css';
import './front/vendors/scroll/scrollable.css';
import './front/vendors/datatable/css/jquery.dataTables.min.css';
import './front/vendors/datatable/css/responsive.dataTables.min.css';
import './front/vendors/datatable/css/buttons.dataTables.min.css';
import './front/vendors/text_editor/summernote-bs4.css';
import './front/vendors/morris/morris.css';
import './front/vendors/material_icon/material-icons.css';
import './front/css/metisMenu.css';
import './front/css/style.css';
import './front/css/colors/default.css'; 
 

import './front/parsley.css';
import Analytics from "./page/analytics";
import PushOnPortal from "./page/pushOnPortal";
import Orders from "./page/order/orders";
import OrderStatus from "./page/order/orderStatus";
import BulkUpload from "./page/bulkUpload";
import Ndr from "./page/ndr";
import ProductsPage from "./page/products";
 

 
import './front/css/client/zoom/zoom/xzoom.css'; 
 
import './front/css/client/zoom/magnific/magnific-popup.css';
import './front/css/client/css/normal.css'; 
import './front/css/client/css/thumbelina.css'; 
import { baseUrl } from "./helper/helper";
import UserLogin from "./page/userspage/userlogin";
import UserSignUp from "./page/userspage/usersignup";
import PaymentInvoice from "./page/invoice/paymentInvoice";
import PurchaseBill from "./page/invoice/purchaseBill";
import Setting from "./page/setting/setting";
import ApiConfiguration from "./page/setting/apiConfiguration";
import CompanyInfo from "./page/setting/companyInfo";
import PaymentSetting from "./page/setting/paymentSetting";
import Droshipper from "./page/setting/droshipper";
import Contract from "./page/setting/contract";
import AccountSetting from "./page/setting/accountSetting";
import Support from "./page/support";

 

function App() {
   
  const location = useLocation();
  const notSidebar = ['/login', '/signup'];
    useEffect(()=>{
      $("#sidebar_menu").metisMenu();
        $("#admin_profile_active").metisMenu();

        $(".open_miniSide").click(function() {
            $(".sidebar").toggleClass("mini_sidebar");
            $(".main_content").toggleClass("full_main_content");
            $(".footer_part").toggleClass("full_footer");
        });

        // Clean up to avoid memory leaks
        return () => {
            $("#sidebar_menu").metisMenu('dispose');
            $("#admin_profile_active").metisMenu('dispose');
            $(".open_miniSide").off("click");
        };
    },[])

  return (
    <div className="crm_body_bg">
      <div>
        <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <title>Routeway</title>
        <link rel="icon" href={`${baseUrl}front/img/mini_logo.png`} type="image/png" />
       
       {/* side nav bar */}
        {!notSidebar.includes(location.pathname)?<Sidebar />:''}
       {/* <Router>   */}
        <Routes> 

          <Route path="/" element={<HomePage />} /> 
          <Route path="/product-details/:id" element={<ProductDetails />} />
          <Route path="/category" element={<Category />} /> 
          <Route path="/category/:slug" element={<Category />} /> 
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/push-on-portal" element={<PushOnPortal />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders-status" element={<OrderStatus />} />
          <Route path="/upload-bulk-order" element={<BulkUpload />} />
          <Route path="/ndr" element={<Ndr />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/login" element={<UserLogin />} />
          <Route path="/signup" element={<UserSignUp />} />
          <Route path="/payment-invoice" element={<PaymentInvoice />} />
          <Route path="/purchase-bill" element={<PurchaseBill />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/api-configuration" element={<ApiConfiguration />} />
          <Route path="/company-info" element={<CompanyInfo />} />
          <Route path="/payment-setting" element={<PaymentSetting />} />
          <Route path="/dropshipper" element={<Droshipper />} />
          <Route path="/contract" element={<Contract />} />
          <Route path="/account-setting" element={<AccountSetting />} />
          <Route path="/support" element={<Support />} />

        </Routes> 
    {/* </Router> */}
        

      </div>
    </div>
  );
}


const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
// export default App;
